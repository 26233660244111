var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.priceQuotationDetails)?_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("#")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.$t("StoreItems.data")))]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(_vm._s(_vm.$t("general.quantity")))]),_c('th',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.$t("StoreItems.price.bestprice")))]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(_vm._s(_vm.$t("status")))]),_vm._m(0)]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("general.image")))]),_c('th',[_vm._v(_vm._s(_vm.$t("general.code")))]),_c('th',{staticClass:"cell-md"},[_vm._v(_vm._s(_vm.$t("general.name")))]),_c('th',[_vm._v(_vm._s(_vm.$t("StoreItems.price.total")))]),_c('th',[_vm._v(_vm._s(_vm.$t("StoreItems.price.one")))]),_c('th',[_vm._v(_vm._s(_vm.$t("suppliers.name")))])])]),_c('tbody',_vm._l((_vm.priceQuotationDetails),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index))]),_c('td',[_c('img',{staticClass:"item-img-table",attrs:{"src":_vm.fullPathFileFromServer(
                item.storeItemInfoData
                  ? item.storeItemInfoData.serviceImagePath
                  : '',
                _vm.defaultImg
              ),"onerror":("this.src='" + _vm.defaultImg + "'")}})]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( item.storeItemInfoData ? item.storeItemInfoData.fullCode : "" ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( item.storeItemInfoData ? item.storeItemInfoData.storeItemNameCurrent : "" ))+" ")]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(item.storeItemQuantity)))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(item.bestPriceTotalItemsAmountWithCurrency)))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(item.bestPriceStoreItemAmountWithCurrency)))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( item.bestPricePlaceInfoData ? item.bestPricePlaceInfoData.placeNameCurrent : "" ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( item.lastPriceQuotationHistoryWorkInfoData ? item.lastPriceQuotationHistoryWorkInfoData .quotationDetailsHistoryWorkTypeNameCurrent : "" ))+" ")]),_c('td',{staticClass:"fmenu-item-container"},[_c('FloatingMenu',[(_vm.checkPrivilege(_vm.hasQuotationPriceHistoryAdd()))?_c('li',[_c('button',{attrs:{"title":_vm.$t('QuotationPriceHistories.add')},on:{"click":function($event){_vm.setStoreItemData(item);
                  _vm.openBottomSheet('QuotationPriceHistoryAdd');}}},[_c('img',{attrs:{"src":require("@/assets/images/plus.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasQuotationPriceHistory()))?_c('li',[_c('router-link',{attrs:{"to":{
                  name: 'QuotationPriceHistories',
                  params: {
                    priceQuotationDetailsToken:
                      item.priceQuotationDetailsToken,
                  },
                },"title":_vm.$t('QuotationPriceHistories.modelName')}},[_c('img',{attrs:{"src":require("@/assets/images/QuotationPriceHistories.svg")}})])],1):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setStoreItemData(item);
                  _vm.openBottomSheet('PriceQuotationInfoPropertyTypesInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])])])],1)])}),0)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{attrs:{"rowspan":"2"}},[_c('i',{staticClass:"fas fa-sliders-h"})])}]

export { render, staticRenderFns }