var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":'PriceQuotationInfoPropertyTypesInfo',"size":"xl","headerText":_vm.$t('StoreItems.data'),"headerIcon":_vm.priceQuotationDetail.icon}},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.priceQuotationDetail.storeItemInfoData
          ? _vm.priceQuotationDetail.storeItemInfoData.fullCode
          : '',"title":_vm.$t('StoreItems.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.priceQuotationDetail.storeItemInfoData
          ? _vm.priceQuotationDetail.storeItemInfoData.storeItemNameCurrent
          : '',"title":_vm.$t('StoreItems.name'),"imgName":'StoreItems.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.priceQuotationDetail.storeItemQuantity,"title":_vm.$t('general.quantity'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.priceQuotationDetail.lastPriceQuotationHistoryWorkInfoData
          ? _vm.priceQuotationDetail.lastPriceQuotationHistoryWorkInfoData
              .quotationDetailsHistoryWorkTypeNameCurrent
          : '',"title":_vm.$t('status'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.priceQuotationDetail.bestPriceTotalItemsAmountWithCurrency,"title":_vm.$t('StoreItems.price.bestTotalPrice'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.priceQuotationDetail.bestPriceStoreItemAmountWithCurrency,"title":_vm.$t('StoreItems.price.bestOnePrice'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.priceQuotationDetail.bestPricePlaceInfoData
          ? _vm.priceQuotationDetail.bestPricePlaceInfoData.placeNameCurrent
          : '',"title":_vm.$t('suppliers.name'),"imgName":'suppliers.svg'}}),_c('PropertyTypesInfoTable',{staticClass:"col-12",attrs:{"propertyTypesData":_vm.priceQuotationDetail.storeItemPropertyTypesData}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }